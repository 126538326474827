import axios from "axios";

const request = axios.create({
  baseURL: process.env.VUE_APP_URL || "http://localhost:4000/api/v1",
});


function setAuthorizationHeader(config) {
  const accessToken = localStorage.getItem("accessToken") || getAccessTokenFromCookie();
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    config.headers["Content-Type"] = "application/json";
  }
  return config;
}

function getAccessTokenFromCookie() {
  const match = document.cookie.match(new RegExp('(^| )' + "ACCESSTOKEN" + '=([^;]+)')); 
  return match ? match[2] : null;
}

request.interceptors.request.use(
  (config) => setAuthorizationHeader(config),
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      return Promise.reject(response.message);
    }
    // if (response.data.message) {
    //   Swal.fire({
    //     position: "top-center",
    //     icon: "success",
    //     text: response.data.message,
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default request;