import request from "../utils/request";
class CompanyService {
  static getCompany(query = {}) {
    return request({
      url: "/studentUniversitario",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static getQuestions(query = {}) {
    return request({
      url: "/vacante/questions",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static postulateChange(data) {
    return request({
      url: "/company/postulate-user-change",
      method: "post",
      timeout: 60000,
      data,
    });
  }

  //TODO: Sección Editar Vacantes
  static vacantesById(query = {}) {
    return request({
      url: "/company/vacanteId/" + query,
      method: "get",
      timeout: 60000
    });
  }
  static updateInfoVacanteBecario(data) {
    return request({
      url: "/company/editarvacante/" + data._id,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static vacantesView(query = {}) {
    return request({
      url: "/vacantes/view",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static vacantes(query = {}) {
    return request({
      url: "/vacantes",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static getPostulates(query = {}) {
    return request({
      url: "/company/postulates-user",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }

  static addComment(data) {
    return request({
      url: "/company/postulate-user-comments",
      method: "post",
      timeout: 60000,
      data,
    });
  }

  static postulateCompany(data) {
    return request({
      url: "/company/postulate",
      method: "post",
      timeout: 60000,
      data,
    });
  }
  static getVacantes(query = {}) {
    return request({
      url: "/company/vacantes",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static getContratados(query = {}) {
    return request({
      url: "/company/vacantes/contratados",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static getContratadosClients(query = {}) {
    return request({
      url: "/company/vacantes/clients/contratados",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static exportExcel(query = {}) {
    return request({
      url: "/company/vacantes/contratados/exportExcel",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  static createCompany(data) {
    return request({
      url: "/users/formCompany",
      method: "post",
      timeout: 60000,
      data,
    });
  }

  static updateVigenciaVacante(data) {
    return request({
      url: "/vacantes/updateVigencia/:companyId",
      method: "put",
      timeout: 1000,
      data,
    });
  }

  static updateRestartVacante(data) {
    let { companyId, vacanteId } = data;
    return request({
      url: `/vacantes/updateRestartVacante/${companyId}/${vacanteId}`,
      method: "put",
      timeout: 1000,
      data,
    });
  }

  static getDasboardCompany() {
    return request({
      url: "/admin/dashboardCompany",
      method: "get",
      timeout: 60000,
    });
  }

  static createVacanteDasboard(data) {
    return request({
      url: "/admin/dashboardCompany",
      method: "post",
      timeout: 60000,
      data
    });
  }

  static updateVacanteDasboard(data) {
    return request({
      url: "/admin/dashboardCompany/" + data.id,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static updateDashboardByVacante(data) {
    return request({
      url: "/admin/dashboardCompanyByVacante/" + data._id_vacante,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getDasboardCompanyById(query = {}) {
    return request({
      url: "/admin/dashboardCompany/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getCompanyUser(query = {}) {
    return request({
      url: "/user/company",
      method: "get",
      timeout: 60000,
      params: query
    });
  }
  static updateInformacionCompany(data) {
    return request({
      url: "/company/editinformation",
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getDasboardCompanyByUserId(query = {}) {
    return request({
      url: "/admin/dashboardCompanyByUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getDasboardCompanyFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/dashboardCompanyFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static closeVacanteById(query = {}) {
    return request({
      url: "/vacantes/suspendedVacanteById/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static deleteVacanteById(query = {}) {
    return request({
      url: "/admin/dashboardCompany/" + query,
      method: "delete",
      timeout: 60000
    });
  }

  static getVacantesTotalesByUserId(query = {}) {
    return request({
      url: "/admin/vacantesTotalesByUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesTotalesFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesTotalesFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByKPIAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByKPIAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByKPIFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByKPIFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByNivelAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByNivelAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByNivelFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByNivelFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }


  static getVacantesByCompanyAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByCompanyAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByCompanyFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByCompanyFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  //////

  static getVacantesByResponsableAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByResponsableAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByResponsableFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByResponsableFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }


  static getVacantesByAreaAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByAreaAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByAreaFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByAreaFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByStatusAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByStatusAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByStatusFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByStatusFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByPromedioDiasCierreAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByPromedioDiasCierreAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByPromedioDiasCierreFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByPromedioDiasCierreFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  ///////

  static getVacantesTotales() {
    return request({
      url: "/admin/vacantesTotales",
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByNivel() {
    return request({
      url: "/admin/vacantesByNivel",
      method: "get",
      timeout: 60000
    });
  }
  static getVacantesByCompany() {
    return request({
      url: "/admin/vacantesByCompany",
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByResponsable() {
    return request({
      url: "/admin/vacantesByResponsable",
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByArea() {
    return request({
      url: "/admin/vacantesByArea",
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByStatus() {
    return request({
      url: "/admin/vacantesByStatus",
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByPromedioDiasCierre() {
    return request({
      url: "/admin/vacantesByPromedioDiasCierre",
      method: "get",
      timeout: 60000
    });
  }

  static getCountVacantesEmpresa(query = {}) {
    return request({
      url: "/get/count/vacantesCompany",
      method: "get",
      timeout: 60000,
      params: query
    });
  }

  static getCountPostulantesEmpresa(query = {}) {
    return request({
      url: "/get/count/postulantesCompany",
      method: "get",
      timeout: 60000,
      params: query
    });
  }
  static getFederalEntities(query = {}) {
    return request({
      url: "/estado/list",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static getLasItemDashboard() {
    return request({
      url: "/admin/lastItem",
      method: "get",
      timeout: 60000
    });
  }
  static getLasItemDashboardByUser(data) {
    const { userId } = data;
    return request({
      url: "/admin/lastItemByIdUser/" + userId,
      method: "get",
      timeout: 60000
    });
  }
  static GenerateIA(data) {
    return request({
      url: "/company/ia/data",
      method: "POST",
      timeout: 60000,
      data
    });
  }
  static getPostulantesByVacante(query = {}) {
    return request({
      url: "/get/count/postulaciones/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getNiveles(query = {}) {
    return request({
      url: "/nivel/list",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }

  static getEstatus(query = {}) {
    return request({
      url: "/estatus/list",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }

  static getVacantesByFechaFinVsFechaTernaAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByFechaFinVsTernaAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByFechaFinVsFechaTernaFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByFechaFinVsTernaFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByFechaTernaAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByFechaTernaAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByFechaTernaFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByFechaTernaFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByFechaTernaAndUserIdNoCerrada(query = {}) {
    return request({
      url: "/admin/vacantesByFechaTernaAndUserIdNoCerrada/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByFechaTernaFilterNoCerrada(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByFechaTernaFilterNoCerrada/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByFechaFinAndUserIdNoCerrada(query = {}) {
    return request({
      url: "/admin/vacantesByFechaFinAndUserIdNoCerrada/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByFechaFinFilterNoCerrada(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByFechaFinFilterNoCerrada/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByFechaFinAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByFechaFinAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByFechaFinFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByFechaFinFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

  static getVacantesByFechaAdmisionAndUserId(query = {}) {
    return request({
      url: "/admin/vacantesByFechaAdmisionAndUserId/" + query,
      method: "get",
      timeout: 60000
    });
  }

  static getVacantesByFechaAdmisionFilter(data) {
    const { userId } = data;
    return request({
      url: "/admin/vacantesByFechaAdmisionFilter/" + userId,
      method: "put",
      timeout: 60000,
      data
    });
  }

}

export default CompanyService;
