import request from "../utils/request";
class UserService {
  static getStudent(query = {}) {
    return request({
      url: "/studentUniversitario",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static checkedLogin(query = {}) {
    return request({
      url: "/user/checked",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static getEstado(query = {}) {
    return request({
      url: "/estado/list",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }

  static savedImage(data) {
    return request({
      url: "/file/upload",
      method: "post",
      timeout: 6000,
      data,
    });
  }
  static socialMedia(data) {
    return request({
      url: "/socialMedia/user",
      method: "post",
      timeout: 6000,
      data,
    });
  }

  static postInformation(data) {
    return request({
      url: "",
      method: "post",
      data,
    });
  }

  static signupEmail(data) {
    return request({
      url: "/users/signup",
      method: "post",
      data,
      timeout: 10000,
    });
  }
  static loginEmail(data) {
    return request({
      url: "/users/login",
      method: "post",
      data,
      timeout: 10000,
    });
  }
  static forgotPassword(data) {
    return request({
      url: "/users/forgot-password",
      method: "post",
      data,
    });
  }
  static resetPassword(data) {
    return request({
      url: "/users/resetPassword",
      method: "patch",
      data,
      timeout: 10000,
    });
  }
  static createVacantes(data) {
    return request({
      url: "/users/create-vacante",
      method: "post",
      data,
      timeout: 60000,
    });
  }
  static formStudents(data) {
    return request({
      url: "/users/formstudent",
      method: "post",
      data,
      timeout: 10000,
    });
  }

  static postInformationTotal(data) {
    return request({
      url: "/users/socialmedia",
      method: "post",
      data,
    });
  }
  static templateUpdateForm(data) {
    return request({
      url: "/users/templateForm/:studentId",
      method: "put",
      data,
      timeout: 1000,
    });
  }
  static updateObjetivoProfesional(data) {
    return request({
      url: "/student/objetivoprofesional/:studentId",
      method: "put",
      data,
      timeout: 1000,
    });
  }
  //TODO: OBTENER VACANTES DE TODAS LAS EMPRESAS
  static getVacantesCompanies(query = {}) {
    return request({
      url: "/student/vacantes",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  //TODO: METODO QUE OBTIENE LAS VACANTES QUE SE MUESTRAN EN HOMEPAGE
  static vacantesInicio(query = {}) {
    return request({
      url: "/inicio/vacantes",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }
  //TODO: METODO QUE OBTIENE LAS VACANTES QUE SE MUESTRAN EN HOMEPAGE
  static vacantesVista(query = {}) {
    return request({
      url: "/inicio/vacantes/vista/" + query,
      method: "get",
      timeout: 60000,
    });
  }
  static updateSocialLinkedin(data) {
    return request({
      url: "/student/updatelinkedin/:studentId",
      method: "put",
      data,
      timeout: 6000,
    });
  }
  static updateInformacionPersonal(data) {
    return request({
      url: "/student/updateinformacionpersonal/:studentId",
      method: "put",
      data,
      timeout: 6000,
    });
  }
  static updateDocumentacion(data) {
    return request({
      url: "/student/updatedocumentacionpersonal/:studentId",
      method: "put",
      data,
      timeout: 6000,
    });
  }
  static getPostulaciones() {
    return request({
      url: "/users/postulaciones",
      method: "GET",
      timeout: 10000,
    });
  }
  static getDocuments(params) {
    return request({
      url: "/users/documents",
      method: "GET",
      timeout: 10000,
      params: params
    });
  }
  static updateDocumentsPostulante(data) {
    return request({
      url: "/users/documents/upload",
      method: "PUT",
      data,
    });
  }
  static updateDocumentsUsers(data) {
    return request({
      url: "/users/documents/upload/file",
      method: "PUT",
      data,
    });
  }
  static verificationStudents(data) {
    return request({
      url: "/users/verification/student",
      method: "post",
      data,
      timeout: 10000,
    });
  }
  static getVerifiedData(query = {}) {
    return request({
      url: "/infoVerification",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static getAllPromos(query = {}) {
    return request({
      url: "/users/getPromo",
      method: "get",
      timeout: 6000,
      params: query,
    });
  }
}
export default UserService;
