<template>
  <main>
    <div class="general-container">
      <div class="title-container-information">
        <p class="fs-1">REGISTROS</p>
      </div>
      <div class="J-body-page J-flex wrap center">
        <a class="J-target" v-for="(item, i) in listData" :key="i + 1" :title="item.title">
          <div class="modal-dialog modal-dialog-centered">
            <div class="J-target-icon">
              <!-- <i :class="item.icon"></i> -->
              <box-icon :name='item.icon' type="solid" size='lg' color="#03337a"></box-icon>
            </div>
          </div>
          <div class="J-target-description">
            {{ item.count }}
          </div>
          <div class="J-target-title">
            {{ item.title }}
          </div>
        </a>
      </div>
    </div>
  </main>
</template>
<script>
import { AdminService } from "@/services";

export default {
  data: () => ({
    listData: [
      {
        title: "Vacantes Publicadas",
        icon: "id-card",
        count: "",
      },
      {
        title: "Empresas",
        icon: "building",
        count: "",
      },
      {
        title: "Total Inscritos",
        icon: "abacus",
        count: "",
      },
      {
        title: "Usuarios Totales",
        icon: "user-plus",
        count: 0,
      },
      {
        title: "Pre Universitarios",
        icon: "child",
        count: 0,
      },
      {
        title: "Universitarios",
        icon: "award",
        count: "0",
      },
      {
        title: "Ex Universitarios",
        icon: "graduation",
        count: 0,
      },
      {
        title: "Incompletos",
        icon: "bug",
        count: 0,
      },
    ],
  }),
  beforeMount() {
    this.dataUser = JSON.parse(localStorage.getItem("user") || null);
    this.getCompany();
    this.getVacante();
    this.getUsers();
    this.getUserCount();
    this.getPreUniversitarioCount();
    this.getUniversitarioCount();
    this.getExUniversitarioCount();
    this.getUsersIncompletos();
  },
  methods: {
    async getCompany() {
      try {
        const response = await AdminService.getCompanyCount();
        this.listData[1].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getVacante() {
      try {
        const response = await AdminService.getVacantesCount();
        this.listData[0].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getUsers() {
      try {
        const response = await AdminService.getStudentCount();
        this.listData[2].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getUserCount() {
      try {
        const response = await AdminService.getCountUsuariosTotales();
        this.listData[3].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getUsersIncompletos() {
      try {
        const response = await AdminService.getCountUsuariosNoRegistrados();
        this.listData[7].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getPreUniversitarioCount() {
      try {
        const response = await AdminService.getCountPreUniversitarios();
        this.listData[4].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getUniversitarioCount() {
      try {
        const response = await AdminService.getCountUniversitarios();
        this.listData[5].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    async getExUniversitarioCount() {
      try {
        const response = await AdminService.getCountExUniversitarios();
        this.listData[6].count = response.data;
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
  },
};
</script>
