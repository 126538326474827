import request from "../utils/request";

class RecibosService {
    static getSemanasByAnio(query = {}) {
        return request({
            url: "/admin/recibo/semanas/" + query,
            method: "get",
            timeout: 60000
        });
    }

    static getQuincenasByAnio(query = {}) {
        return request({
            url: "/admin/recibo/quincenas/" + query,
            method: "get",
            timeout: 60000
        });
    }

    static getMesesByAnio(query = {}) {
        return request({
            url: "/admin/recibo/meses/" + query,
            method: "get",
            timeout: 60000
        });
    }

    static saveRecibo(data) {
        return request({
            url: "/admin/recibo/save",
            method: "post",
            timeout: 60000,
            data
        });
    }
    static getRecibosByPostulante() {
        return request({
            url: "/admin/recibo/list",
            method: "get",
            timeout: 60000
        });
    }

    static recibosByPeriodo(data) {
        return request({
            url: "/admin/recibo/recibos-periodo",
            method: "post",
            timeout: 60000,
            data
        });
    }

    static aceptarRecibo(data) {
        return request({
            url: "/admin/recibo/aceptar",
            method: "post",
            timeout: 60000,
            data
        });
    }

}

export default RecibosService;